import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="flex flex-col justify-center align-middle items-center space-y-6 bg-gray-950 h-screen w-screen ">
          {/* <FuzzyOverlay /> */}
          <a
            href="https://www.instagram.com/sefutography"
            rel="noopener noreferrer"
            className="flex justify-center align-middle items-center"
          >
            <img
              src="/assets/logo.svg"
              alt="Logo"
              className=""
              style={{ width: "25%" }}
            />
          </a>
          <h1 className="text-base ">Greatness is in progress.</h1>
        </div>
      </header>
    </div>
  );
}

export default App;
